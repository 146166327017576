
    export default ({
        name: "PasswordChange",
        data: () => {
            return {
                password_d: false,
            }
        },
        setup() {
            return {

            };
        },
    });
